<template>
    <div
        class="relative"
        :class="
            isCard &&
            'pt-10 px-8 lg:px-16 pb-11 lg:pb-14 text-black bg-white rounded-md border border-black border-opacity-10'
        "
    >
        <transition name="fade">
            <div
                v-if="isNewsletterSubscribeRequestSent"
                class="flex flex-col absolute -ml-1 w-[calc(100%+8px)] h-full top-0 left-0 py-4 px-0 lg:p-8 justify-center items-center text-center z-10"
                :class="isCard ? 'text-black bg-white' : 'text-white bg-blue-900'"
            >
                <i class="ri-checkbox-circle-fill text-4xl mb-2 text-green"></i>
                <span class="mb-4 text-[1.5rem] lg:text-2xl font-Inter-Bold" :class="isCard && 'text-black'">
                    Sikeresen feliratkozott hírlevelünkre
                </span>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et in nesciunt quod?</p>
                <div @click="hideFeedbackMessage">
                    <PButton label="Rendben" :appearance="isCard ? 'black' : 'white'" class="w-max mt-8" />
                </div>
            </div>
        </transition>
        <h3 class="font-Inter-Bold text-xl lg:text-2xl mb-5 text-white" :class="isCard && 'text-black'">
            Iratkozz fel hírlevelünkre!
        </h3>
        <p class="text-sm font-Inter-Regular leading-5 text-white" :class="isCard && 'text-black'">
            Irazkozz fel, hogy értesülj legújabb Akcióinkról, a kosárban hagyott termékeidről, vagy éppen a tech világ
            aktuális híreiről. Hetente maxium 2-3 hírlevelet küldönk az általad kíválasztott kategóriákból.
        </p>
        <ValidationObserver ref="form" slim>
            <form novalidate @submit.prevent="onSubmit">
                <div class="flex mt-6 gap-x-3 flex-wrap">
                    <div class="w-full lg:w-[calc(50%-0.375rem)]">
                        <ValidationProvider v-slot="{ errors }" vid="last_name" tag="div">
                            <Input
                                v-model="last_name"
                                has-icon
                                icon="user"
                                :error="errors"
                                placeholder="Add meg a vezetékneved!"
                                :label="hasLabels ? 'Vezetéknév' : null"
                                :required="false"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="w-full lg:w-[calc(50%-0.375rem)] mt-4 lg:mt-0">
                        <ValidationProvider v-slot="{ errors }" vid="first_name" tag="div">
                            <Input
                                v-model="first_name"
                                has-icon
                                icon="user"
                                :error="errors"
                                placeholder="Add meg a keresztneved!"
                                :label="hasLabels ? 'Keresztnév' : null"
                                :required="false"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="w-full mt-4 lg:mt-5">
                        <ValidationProvider v-slot="{ errors }" vid="email" tag="div">
                            <Input
                                v-model="email"
                                has-icon
                                icon="mail"
                                :error="errors"
                                :class="isCard ? 'text-black' : 'text-white'"
                                placeholder="Add meg az email címedet!"
                                :label="hasLabels ? 'E-mail cím' : null"
                                :required="false"
                                :is-error-visible="isErrorVisible"
                            />
                            <div v-if="isSubscribed" class="text-left ml-auto mt-2 pl-4">
                                <span class="p-error">{{ isSubscribed }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" vid="accept_privacy_policy" tag="div" class="mb-2">
                            <Input
                                :id="`gdpr-${index}`"
                                v-model="accept_privacy_policy"
                                class="mt-6 text-white"
                                is-checkbox
                                :error="errors"
                                :class="isCard ? 'text-black' : 'text-white'"
                                label="Elolvastam és elfogadom az oldal <a href='/adatvedelmi-nyilatkozat' target='_blank'>Adatvédelmi irányelveit.</a>"
                                :is-error-visible="isErrorVisible"
                            />
                        </ValidationProvider>
                    </div>
                </div>
                <span
                    v-if="false"
                    class="text-xs pl-8 font-Inter-Regular opacity-80 leading-5"
                    :class="isCard ? 'text-black' : 'text-white'"
                    >This site is protected by <a class="font-Inter-Bold" href="/">reCAPTCHA</a> and the Google
                    <a class="font-Inter-Bold" href="/">Privacy Policy</a> and
                    <a class="font-Inter-Bold" href="/">Terms of Service</a> apply.</span
                >
                <div class="w-full flex justify-end">
                    <div @click="onSubmit">
                        <PButton
                            type="Button"
                            appearance="primary"
                            label="Feliratkozom"
                            class="w-full lg:w-auto lg:ml-auto mt-7 mb-4"
                        />
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    name: 'NewsletterForm',
    props: {
        isCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        index: {
            type: Number,
            required: false,
            default: 1,
        },
        hasLabels: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isNewsletterSubscribeRequestSent: false,
            isErrorVisible: false,
            last_name: '',
            first_name: '',
            email: '',
            isSubscribed: '',
            accept_privacy_policy: false,
        };
    },
    async mounted() {
        try {
            await this.$recaptcha.init();
        } catch (e) {
            console.log(e);
        }
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },
    methods: {
        async onSubmit() {
            this.isErrorVisible = true;

            try {
                const token = await this.$recaptcha.execute('login');
                console.log('ReCaptcha token:', token);

                await this.$axios
                    .$post('/newsletter', {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        email: this.email,
                        accept_privacy_policy: this.accept_privacy_policy ? 1 : 0,
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.success === true) {
                            this.isNewsletterSubscribeRequestSent = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                            this.isSubscribed = res.error?.subscribed;
                        }
                    });

                await this.$recaptcha.reset();
            } catch (err) {
                console.log(err);
            }
        },
        hideFeedbackMessage() {
            this.isNewsletterSubscribeRequestSent = false;
            this.first_name = '';
            this.last_name = '';
            this.email = '';
            this.isSubscribed = '';

            this.accept_privacy_policy = false;
        },
    },
};
</script>
