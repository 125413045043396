var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap gap-[9px] items-start lg:items-center",class:!_vm.isProductCard ? 'flex-row' : 'flex-col lg:flex-row'},[_c('div',{staticClass:"flex items-center gap-x-[9px]"},_vm._l((5),function(i){return _c('div',{key:`star-${i}`,staticClass:"star",class:{
                'star--active': i <= Math.floor(_vm.ratingValue),
                'star--half-active': i === Math.ceil(_vm.ratingValue) && !Number.isInteger(_vm.ratingValue),
                'star--inactive': i > Math.ceil(_vm.ratingValue),
            }})}),0),_vm._v(" "),(
            false // reviewCount
        )?_c('span',{staticClass:"font-Inter-Semibold text-xs opacity-50 leading-none"},[_vm._v(_vm._s(_vm.ratingValue.toFixed(1))+" ("+_vm._s(_vm.reviewCount)+" vélemény)")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }