<template>
    <div v-if="highlighted_categories" class="relative">
        <div
            class="flex w-[calc(100%+2.5rem)] -ml-5 lg:ml-0 lg:w-full flex-nowrap gap-x-4 lg:gap-x-6 items-center overflow-x-scroll nicescroll-light lg:overflow-visible px-4 lg:px-0"
            :class="
                isOverlay
                    ? 'text-white justify-start mb-6 pb-4 border-b border-white border-opacity-20'
                    : 'justify-center mb-6'
            "
        >
            <h3 v-if="isOverlay" class="font-Inter-Bold lg:mr-4 uppercase text-lg lg:text-xl hidden md:flex">
                Kiemelt kategóriák
            </h3>
            <button
                v-for="category in cats.slice(0, 7)"
                :key="`category-${category.translations[0].id}`"
                class="whitespace-nowrap font-Inter-Bold bg-opacity-0 hover:bg-opacity-5 py-1 px-5 rounded-full transition border hover:border-opacity-100 border-transparent"
                :class="{
                    'text-white hover:text-white border-opacity-100 bg-opacity-100 hover:bg-opacity-100':
                        isActiveTab(category.translations[0].id) ||
                        selectedHighlightedCategory === parseInt(category.translations[0].id),
                    'bg-blue-100 hover:text-blue-100 hover:border-blue-100': !isOverlay,
                    'bg-white': isOverlay,
                    'text-black hover:text-black':
                        (isOverlay && isActiveTab(category.translations[0].id)) ||
                        selectedHighlightedCategory === parseInt(category.translations[0].id),
                }"
                @click="setHighlightedCategories(category.translations[0].id)"
            >
                {{ category.translations[0].name }}
            </button>
        </div>
        <div v-if="products && products.length">
            <div class="grid grid-cols-10 gap-3 lg:gap-4 mb-16 lg:mb-24">
                <div
                    v-for="product in products.slice(0, $device.isDesktop ? 5 : 6)"
                    :key="`product-${product.id}`"
                    class="col-span-5 lg:col-span-2 h-full"
                    :class="product?.has_banner ? 'lg:col-span-12' : ' col-span-5 lg:col-span-3'"
                >
                    <div class="h-full" :class="product?.has_banner && 'lg:grid lg:grid-cols-12 lg:gap-x-8'">
                        <div class="h-full" :class="product?.has_banner && 'lg:col-span-3'">
                            <ProductCard
                                :title="product.name"
                                :rating="5"
                                :has-btn-text="false"
                                :product="product"
                                :slug="
                                    localePath({
                                        name: 'products-category-product',
                                        params: {
                                            category: product?.category_slug,
                                            product: product?.slug,
                                        },
                                    })
                                "
                                class="h-full"
                                :brutto-price="product.price"
                                :is-favorite="product.isFavorite"
                                :price="product.price"
                                :discounted-price="product.discounted_price"
                                :md="$device.isMobile"
                                :image="product.category_name === 'WonderWood' ? product.image : product.preview"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center mb-12 lg:mb-40 flex flex-col items-center justify-center h-full mt-16">
            <i class="sad-emoji mb-3" data-not-lazy></i>
            <h4 class="font-Inter-Bold text-xl lg:text-[1.5rem] mb-4 leading-[2rem]">
                Sajnos a megadott keresési feltételekkel<br />
                nem találtunk termékeket
            </h4>
            <p class="lg:w-8/12 text-sm lg:text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit Pariatur, perferendis?
            </p>
        </div>
    </div>
</template>

<script>
import ProductCard from '~/components/Cards/ProductCard/ProductCard.vue';
export default {
    name: 'HighlightedCategories',
    components: {
        ProductCard,
    },
    props: {
        isOverlay: {
            type: Boolean,
            required: false,
            default: false,
        },
        cats: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            products: [],
            selectedHighlightedCategory: null,
            highlighted_categories: [],
            categories: [],
        };
    },
    mounted() {
        this.highlighted_categories = this.cats;
        this.products = this.highlighted_categories[0].products;
        this.setHighlightedCategories(this.highlighted_categories[0].translations[0].id);
    },
    methods: {
        setHighlightedCategories(currentCategory) {
            this.selectedHighlightedCategory = currentCategory;
            this.products = this.highlighted_categories.find(
                (category) => category.translations[0].id === currentCategory
            )?.products;
        },
        isActiveTab(tabId) {
            return this.selectedHighlightedCategory === tabId;
        },
    },
};
</script>
